import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { isEqual } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'tnt-ui/services/feature-flags';
import type PostHogFeaturesService from 'tnt-ui/services/posthog-features';
import type SessionService from 'tnt-ui/services/session';

export default class OwnerScopeContextService extends Service {
  @service declare session: SessionService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare posthogFeatures: PostHogFeaturesService;

  @tracked ownerId? = '';

  get scopeFilters() {
    return [
      {
        id: '',
        name: 'All Containers',
      },
      {
        id: this.session.currentUser.id,
        name: 'My Containers',
      },
    ];
  }

  get currentScope() {
    return this.scopeFilters.find(({ id }) => isEqual(id, this.ownerId));
  }

  @action
  async initialScope() {
    if (this.featureFlags.isEnabled('myContainers') || this.posthogFeatures.isPosthogFeatureEnabled('my-containers')) {
      try {
        const userCargoMapping = await this.session.currentUser.userCargoMapping;
        this.ownerId = isEqual(userCargoMapping?.containersDashboardFilter, 'my') ? this.session.currentUser.id : '';
      } catch {
        return;
      }
    }
  }

  @action
  async addScope({ id }: any) {
    this.ownerId = id;
    const userCargoMapping = await this.session.currentUser.userCargoMapping;

    if (userCargoMapping) {
      userCargoMapping.set('containersDashboardFilter', isEqual(id, this.session.currentUser.id) ? 'my' : 'all');
      await userCargoMapping.save();
    }
  }
}
