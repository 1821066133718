/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/require-computed-property-dependencies, ember/require-tagless-components, ember/use-brace-expansion */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';
import currentSession from 'tnt-ui/decorators/current-session';
import { Delivered, EmptyReturned } from 'tnt-ui/models/cargo';

@currentSession
export default class MarkAsDelivered extends Component {
  @service store;
  @service fetch;
  @service notify;
  @service router;

  errors = [];
  currentTime = moment().toDate();

  @tracked timestamp = this.currentTime;

  @computed('cargo.pickedUpAt')
  get minDate() {
    if (this.cargo.pickedUpAt == null) {
      return null;
    }
    return moment(this.cargo.pickedUpAt).add(30, 'minutes').toDate();
  }

  @computed('cargo.emptyReturnedAt')
  get maxDate() {
    return this.cargo.emptyReturnedAt || this.currentTime;
  }

  @computed('cargo.currentStatus', 'cargo.deliveredAt')
  get canMarkAsDelivered() {
    return (this.cargo.inTerminal || this.cargo.pickedUp) && !this.cargo.deliveredAt;
  }

  @computed('canMarkAsDelivered', 'incorrectTimestamp')
  get saveOnClose() {
    return this.canMarkAsDelivered && !this.incorrectTimestamp;
  }

  _reparseTime(dateTime) {
    let timeZone = this.cargo.timeZone;

    if (timeZone) {
      let timeString = moment(dateTime).format('YYYY-MM-DDTHH:mm');

      return moment.tz(timeString, timeZone).toDate();
    } else {
      return dateTime;
    }
  }

  @action
  setTimestamp(params) {
    this.timestamp = this._reparseTime(params[0]);
  }

  @computed('timestamp', 'minDate', 'maxDate')
  get incorrectTimestamp() {
    return !this.timestamp || this.timestamp < this.minDate || this.timestamp > this.maxDate;
  }

  @action
  closeModal() {
    this.toggleProperty('isMarkingAsDelivered');
  }

  @task
  *markCargoAsDelivered() {
    try {
      const container = yield this.store.findRecord('container', this.cargo.id);
      const newRawEvent = this.store.createRecord('rawEvent', {
        event: 'delivered',
        container: container,
        location: this.cargo.get('shipment.portOfDischarge'), // Could this ever be delivered at the final destination?
        actualAt: this.timestamp,
        dataSource: 'user_input',
      });
      yield newRawEvent.save();

      this.cargo.set('deliveredAt', newRawEvent.timestamp);

      if (this.cargo.currentStatus != EmptyReturned) {
        this.cargo.set('currentStatus', Delivered);
      }

      this.notify.success('Marked container as delivered');
      const currentRoute = this.router.currentRouteName;
      yield this.router.refresh(currentRoute);
    } catch (e) {
      this.notify.error('Unable to save event.');
      window.console.log(e);
    } finally {
      this.toggleProperty('isMarkingAsDelivered');
    }
  }
}
