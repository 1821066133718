/* import __COLOCATED_TEMPLATE__ from './view-share.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { compare, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { all } from 'rsvp';
import ENV from 'tnt-ui/config/environment';

export default class DialogsViewShareComponent extends Component {
  @service store;
  @service dialogs;
  @service notify;
  @service metrics;

  @tracked inviteMessage = 'Send invite';
  @tracked copyMessage = 'Copy link';
  @tracked inputValue = '';
  @tracked validEmails = A([]);

  get appURL() {
    return ENV.APP.APP_URL;
  }

  get publicShareURL() {
    return `${this.appURL}/share/${this.containerView?.id}`;
  }

  get containerView() {
    return this.args.content.containerView;
  }

  get viewShares() {
    return this.containerView.containerViewShares
      .toArray()
      .filter(({ isNew }) => !isNew)
      .sort((a, b) => compare(b.get('createdAt'), a.get('createdAt')));
  }

  unknownProperty(key) {
    return this.args[key] || this.args?.content[key];
  }

  @action
  resolveAndClose() {
    this.args.content.promise.resolve();
    this.closeDialog();
  }

  @action
  rejectAndClose() {
    this.args.content.promise.reject();
    this.closeDialog();
  }

  @action
  async toggleViewAnonymization(value) {
    const containerView = this.containerView;
    containerView.isAnonymized = value;

    if (value) {
      this.trackEvent({
        event: 'view_anonymize_enabled',
        view_id: containerView.get('id'),
      });
    } else {
      this.trackEvent({
        event: 'view_anonymize_disabled',
        view_id: containerView.get('id'),
      });
    }

    try {
      await containerView.save();

      this.notify.success('Successfully saved the container view.');
    } catch (error) {
      this.notify.error("Couldn't save the container view. Please try again.");
    }
  }

  @action
  setEmailsInput(emailsInput) {
    this.emailsInput = emailsInput;
  }

  @action
  onFocusInput() {
    this.emailsInput.focus();
  }

  @action
  async onCopy() {
    await navigator.clipboard.writeText(this.publicShareURL);
    this.copyMessage = 'Copied!';

    later(
      this,
      () => {
        this.copyMessage = 'Copy link';
      },
      1200,
    );
  }

  @action
  async onCopyInvite(url) {
    await navigator.clipboard.writeText(url);
  }

  @action
  onInput(e) {
    this.inputValue = e.target.value;
  }

  @action
  onKeyDown(e) {
    if (e.key === 'Enter') {
      if (isPresent(this.inputValue)) {
        const emails = this.extractEmails(this.inputValue);
        this.validEmails.pushObjects(emails);
        this.inputValue = '';
      }
    }
  }

  @action
  onPaste(e) {
    e.preventDefault();

    const clipboardData = e.clipboardData;

    if (isPresent(clipboardData)) {
      const clipboard = clipboardData.getData('text');
      const emails = this.extractEmails(clipboard);
      this.validEmails.pushObjects(emails);
    }
  }

  @action
  async onSendInvite() {
    await all(
      this.validEmails.map((email) => {
        return this.store
          .createRecord('container-view-share', {
            containerView: this.containerView,
            title: this.containerView.title,
            recipientEmail: email,
          })
          .save();
      }),
    );

    this.trackEvent({
      event: 'view_granular_share_sent',
      view_id: this.containerView.get('id'),
      target_email: this.validEmails.join(', '),
    });

    this.validEmails.clear();
    this.inviteMessage = 'Invites sent!';

    later(
      this,
      () => {
        this.inviteMessage = 'Send invite';
      },
      1200,
    );
  }

  @action
  removeEmail(email) {
    this.validEmails.removeObject(email);
  }

  @task
  *removeViewShare(viewShare) {
    const { recipientEmail, containerView } = viewShare;

    try {
      this.trackEvent({
        event: 'view_granular_share_disabled',
        view_id: containerView.get('id'),
        target_email: recipientEmail,
      });

      yield viewShare.destroyRecord();

      this.notify.success(`Successfully removed the link that you shared with ${recipientEmail}.`);
    } catch (e) {
      console.log(e);
      this.notify.error(`Could not remove the link you shared with ${recipientEmail}.  Please try again.`);
    }
  }

  @task
  *resendViewShare(viewShare) {
    const { recipientEmail, containerView } = viewShare;

    try {
      this.trackEvent({
        event: 'view_granular_share_resend',
        view_id: containerView.get('id'),
        target_email: recipientEmail,
      });

      yield viewShare.resendEmail();

      this.notify.success(`Successfully resent the link that you shared with ${recipientEmail}.`);
    } catch (e) {
      console.log(e);
      this.notify.error(`Could not resend the link you shared with ${recipientEmail}.  Please try again.`);
    }
  }

  @action
  closeDialog() {
    this.dialogs.close('view-share');
  }

  @action
  trackEvent(attrs) {
    this.metrics.trackEvent({
      ...attrs,
      source: 'Container Dashboard',
    });
  }

  extractEmails(clipboard) {
    let data = [];

    if (clipboard.includes('\n')) {
      data = clipboard.split('\n');
    } else if (clipboard.includes(',')) {
      data = clipboard.split(',');
    } else if (clipboard.includes(';')) {
      data = clipboard.split(';');
    } else {
      data = [clipboard];
    }

    let emails = [];
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const email = data[index].trim();
        if (emailPattern.test(email)) {
          emails.push(email);
        }
      }
    }
    return emails;
  }
}
