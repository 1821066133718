/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-get */
import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { isEmpty, isEqual, isPresent } from '@ember/utils';
import * as Sentry from '@sentry/ember';
import { all } from 'ember-concurrency';
import posthog from 'posthog-js';
import { resolve } from 'rsvp';
import ENV from 'tnt-ui/config/environment';
import currentSession from 'tnt-ui/decorators/current-session';

const UserProps = ['id', 'email', 'name', 'jobRole', 'companyName'];
const AccountProps = ['id', 'companyName', 'companyType', 'createdAt'];

@currentSession
export default class SessionSetupService extends Service {
  @service metrics;
  @service routeLayout;
  @service store;
  @service localStorage;
  @service('activity-manager') activityManager;

  async signInUser(user) {
    this.session.set('currentUser', user);

    const currentAccountId = this.session.get('data.current_account_id');

    if (isPresent(currentAccountId)) {
      if (isEqual(currentAccountId, user.get('primaryAccount.id'))) {
        this.session.set('currentUser.currentAccount', user.get('primaryAccount'));
      } else {
        const currentAccount = await this.store.findRecord('account', currentAccountId);
        this.session.set('currentUser.currentAccount', currentAccount);
      }
    } else {
      this.session.set('data.current_account_id', user.get('primaryAccount.id'));
      this.session.set('currentUser.currentAccount', user.get('primaryAccount'));
    }

    this.setAccount();
    this.signedInMetrics(user);
    await user.reload();
    this.routeLayout.setBodyClass('new-nav');
    this.localStorage.writeItem('containerViewIds', user.get('activeContainerViewIds'));
  }

  setAccount() {
    const user = this.get('currentUser');

    // this.activityManager.setupSocket(true);
    if (['staging', 'production', 'demo'].includes(ENV.environment)) {
      this.analyticsIdentify(user);
      this.cannyIdentify(user);
      this.sentryIdentify(user);
      this.postHogIdentify(user);
    }
  }

  recentActivity(limit = 5) {
    return this.store.query('activity', { limit });
  }

  singOut() {
    this.activityManager.remove();
  }

  async loadCurrentUser() {
    const userId = this.session.get('data.authenticated.user_id');

    if (isEmpty(userId)) {
      this.signedOutMetrics();
      return resolve();
    }
    const user = await this.store.findRecord('user', userId);

    await this.signInUser(user);

    try {
      await all(user.accounts.map((account) => account.billingAccount));
    } catch (err) {
      console.error(err);
    }
  }

  async _loadCurrentUser() {
    try {
      await this.loadCurrentUser();
    } catch (err) {
      if (this.session.isAuthenticated && ENV.environment !== 'development') {
        localStorage.removeItem('signedInAs');
        this.session.set('data.current_account_id', null);
        this.session.invalidate();
      }
    }
  }

  signedInMetrics(user) {
    this.set('metrics.context.signedIn', true);
    this.set('metrics.context.userId', user.get('id'));
    this.set('metrics.context.email', user.get('email'));
    this.set('metrics.context.accountId', user.get('primaryAccount.id'));

    const account = user.get('currentAccount');
    const billingAccount = account.get('billingAccount');
    const isTrialActive = billingAccount?.get('isTrialActive') ?? false;
    const railDataEnabled = get(account, 'flags.railData') ?? false;
    const railDataEndDate = get(account, 'flagDetails.railData.endDate');

    this.set('metrics.context.isTrialActive', isTrialActive);
    this.set('metrics.context.railDataEnabled', railDataEnabled);
    this.set('metrics.context.railDataEndDate', railDataEndDate);
  }

  signedOutMetrics() {
    this.set('metrics.context.signedIn', false);
    this.set('metrics.context.userId', null);
    this.set('metrics.context.email', null);
    this.set('tempUser', null);
  }

  analyticsIdentify(user) {
    let {
      id: distinctId,
      email,
      jobRole,
      jobTitle,
      referralSource,
      customerNeeds,
      completedCreateAccount,
      completedUserInfo,
      completedTrackShipments,
      completedInviteUser,
    } = user.getProperties(UserProps);
    let traits = {
      distinctId,
      email,
      jobRole,
      jobTitle,
      referralSource,
      customerNeeds,
      completedCreateAccount,
      completedUserInfo,
      completedTrackShipments,
      completedInviteUser,
    };
    let account = user.get('primaryAccount');
    if (isPresent(account)) {
      let {
        id,
        companyName: name,
        companyType,
        createdAt,
        annualContainerVolume,
      } = account.getProperties(AccountProps);
      traits.companyId = id;
      traits.companyName = name;
      traits.companyType = companyType;
      traits.companyCreatedAt = createdAt;
      traits.company = { name, id, companyType, createdAt };
      traits.annualContainerVolume = annualContainerVolume;
      traits.dateJoined = createdAt;
      // HubSpot tracking
      traits.hutk = this.getCookie('hubspotutk');
    }

    this.metrics.identify(traits);
  }

  cannyIdentify(user) {
    if (isEmpty(ENV.APP.CANNY_APP_ID) && isEmpty(window.Canny)) {
      return;
    }
    let account = {};
    if (!isEmpty(user.primaryAccount)) {
      account.name = user.primaryAccount.companyName;
      account.id = user.primaryAccount.id;
      account.created = user.primaryAccount.createdAt;
    }
    window.Canny('identify', {
      appID: ENV.APP.CANNY_APP_ID,
      user: {
        email: user.email,
        name: user.name,
        id: user.id,
        created: user.createdAt,
        companies: [account],
      },
    });
  }

  sentryIdentify({ id, email }) {
    Sentry.setUser({ id, email });
  }

  postHogIdentify(user) {
    const properties = {
      userId: user.id,
      email: user.email,
      jobRole: user.jobRole,
      accountId: this.currentAccount.id,
      companyName: this.currentAccount.companyName,
      companyType: this.currentAccount.companyType,
      companyCreatedAt: this.currentAccount.createdAt,
      annualContainerVolume: this.currentAccount.annualContainerVolume,
      paidAccount: this.currentAccount.paidAccount,
    };
    posthog.identify(user.id, properties);

    properties['name'] = this.currentAccount.companyName;
    posthog.group('account', this.currentAccount.id, properties);

    const billingAccount = this.currentAccount.belongsTo('billingAccount');
    if (isPresent(billingAccount)) {
      posthog.group('billingAccount', billingAccount.id(), properties);
    }
  }

  getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [key, value] = cookie.trim().split('=');
      if (key === name) {
        return value ? decodeURIComponent(value) : null;
      }
    }
    return null; // Return null if the cookie isn't found
  }
}
