/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import buildUnifiedEventTable from '../../../utils/build-unified-event-table';
import { transportEventMappings } from '../../../utils/event-name-mappings';

/**
 * @param {ContainerModel} container
 */

export default class CargoUnifiedEventView extends Component {
  @tracked eventHistoryEvent = null;

  @action
  openEventHistoryModal(event, clickEvent) {
    this.eventHistoryEvent = event;
    clickEvent.stopPropagation();
  }

  /* Opening the invalidate modal */

  @tracked invalidateModalEvent = null;
  @tracked createModalEvent = null;
  @tracked editModalEvent = null;

  @action
  openInvalidateModal(event, clickEvent) {
    this.invalidateModalEvent = event;
    clickEvent.stopPropagation();
  }

  @action
  openCreateModal(event, clickEvent) {
    if (event == 'new') {
      this.createModalEvent = {};
    } else {
      const eventType = transportEventMappings()[event.event].rawEvents[0];
      this.createModalEvent = { event: eventType };
    }
    clickEvent.stopPropagation();
  }

  @action
  openEditModal(event, clickEvent) {
    this.editModalEvent = event;
    clickEvent.stopPropagation();
  }

  /* Filtering by source */

  @tracked sourceFilter = 'all';
  sourceFilterOptions = [
    { name: 'All', value: 'all' },
    { name: 'Shipping Line', value: 'shipping_line' },
    { name: 'AIS', value: 'ais' },
    { name: 'Terminal', value: 'terminal' },
    { name: 'Rail Carrier', value: 'rail' },
  ];
  get selectedSourceFilterName() {
    return this.sourceFilterOptions.find((filter) => filter.value === this.sourceFilter)?.name;
  }

  /* Expanding/condensing the Unified Event View table */
  @tracked eventExpansions = {};

  @action
  toggleEventExpansion(eventId) {
    this.eventExpansions = {
      ...this.eventExpansions,
      [eventId]: !this.eventExpansions[eventId],
    };
  }

  @action
  toggleAllEventExpansions() {
    this.eventExpansions = this.unifiedEvents.reduce((acc, event) => {
      acc[event.id] = !this.allExpanded;
      return acc;
    }, {});
  }

  get allExpanded() {
    return this.unifiedEvents.every((event) => this.eventExpansions[event.id]);
  }

  /* the main method for creating unified event view */

  get unifiedEvents() {
    return buildUnifiedEventTable(this.args.container, this.sourceFilter);
  }
}
